import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_GUIDE = '/guide', PATH_FAQ = '/faq', PATH_HELP_CENTER = '/helpCenter';

export const GuideRoutesConfig: { [key: string]: RouteConfiguration } = {
  GuideService: {
    path: PATH_GUIDE,
    generatePath: () => PATH_GUIDE,
  },
  FaqService: {
    path: PATH_FAQ,
    generatePath: () => PATH_FAQ,
  },
  HelpCenterService: {
    path: PATH_HELP_CENTER,
    generatePath: () => PATH_HELP_CENTER,
  },
};

const LoadableGuideContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/guide').then(module => module.Guide),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

const LoadableFaqContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/faq').then(module => module.Faq),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

const LoadableHelpCenterContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/helpCenter').then(module => module.HelpCenter),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function GuideRoutes() {
  return (
    <>
      <Route
        path={GuideRoutesConfig.GuideService.path}
        exact={true}
        component={LoadableGuideContainer}
      />
      <Route
        path={GuideRoutesConfig.FaqService.path}
        exact={true}
        component={LoadableFaqContainer}
      />
      <Route
        path={GuideRoutesConfig.HelpCenterService.path}
        exact={true}
        component={LoadableHelpCenterContainer}
      />
    </>
  );
}
