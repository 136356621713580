import { createAction as createSmartAction } from 'redux-smart-actions';
import { Store } from 'redux';
import { RootState } from '../../../store';
import { DispatchRequest, RequestAction, getQuery } from '@redux-requests/core';
import { setAccount } from 'modules/account/store/actions/setAccount';
import { MyNftAbi, CreateContractBytecode } from 'modules/web3/contracts';
import { getJWTToken, getOrgID } from 'modules/common/utils/localStorage';
import axios from 'axios';

export interface ICollectionDetail {
  total: number;
  items: Iitems[];
}

export interface IAttributes {
  trait_type: string;
  value: string;
}
export interface Iitems {
  contractAddress: string;
  createdAt: string;
  description: string;
  iconUrl: string;
  id: number;
  imageUrl: string;
  metadataApiUrl: string;
  name: string;
  ownerAddress: string;
  updatedAt: string;
  links: string;
}


export const fetchCollections = createSmartAction<
  RequestAction<any, ICollectionDetail>,
  [
    {
      index: number;
      size: number;
      address: string;
    }
  ]
>('fetchCollections', ({ index, size, address }) => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/nft-market-console/v1/collections`,
    method: 'get',
    params: {
      offset: index,
      limit: size,
      address: address
    },
  },
  meta: {
    asMutation: false,
    auth: true,
    orgID: true,
    driver: 'axios',
    getData: data => {
      if (data.data.items.length < 1) {
        return {
          total: 0,
          items: []
        };
      }
      return {
        total: data.data.total,
        items: data.data.items
      };
    },
  },
}));


export const uploadImg = createSmartAction<
  RequestAction<any, any>,
  [
    {
      file: any;
    }
  ]
>('uploadImg', ({ file }) => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/nft-cloud-storage/v1/images`,
    method: 'post',
    params: {},
    data: file,
  },
  meta: {
    asMutation: false,
    auth: true,
    orgID: true,
    driver: 'axios',
    getData: data => {
      console.log("----data----------------", data)
      if (data.code === 401) {
        console.log("---------------------")
      }
      return data.data;
    },
  },
}));


export const fetchCreateByteCode = createSmartAction(
  'fetchCreateByteCode', ({
    names,
    symbols,
    imageUrl,
    description,
    iconUrl,
    metadataApiUrl,
    back_image,
    url
  }: {
    names: string;
    symbols: string;
    imageUrl: string;
    description: string;
    iconUrl: string;
    metadataApiUrl: string;
    back_image: string;
    url: string;
  }) => {
  return {
    request: {
      promise: (async function () { })(),
    },
    meta: {
      onRequest: (
        request: { promise: Promise<any> },
        action: RequestAction,
        store: Store<RootState> & { dispatchRequest: DispatchRequest },
      ) => {
        return {
          promise: (async function () {
            const {
              data: { web3, address }, } = getQuery(store.getState(), {
                type: setAccount.toString(),
                action: setAccount,
              });
            const Contracts = new web3.eth.Contract(
              MyNftAbi
            );
            return Contracts?.deploy({
              data: CreateContractBytecode,//合约bytecode
              arguments: ['HelloWorld'] //给构造函数传递参数,使用数组
            }).send({
              from: address,
            }).on('error', (error: any) => {
              console.log(error);
            }).then((res: any) => {
              console.log('res.options.address----->', res.options.address);
              const contractAddress: string = res.options.address;
              return store.dispatchRequest(createInitialize({
                names: names,
                symbols: symbols,
                contractAddress: contractAddress,
                imageUrl: imageUrl,
                description: description,
                iconUrl: iconUrl,
                metadataApiUrl: metadataApiUrl,
                back_image: back_image,
                url: url,
              }))
            })
          })(),
        };
      },
      asMutation: false,
    },
  };
});


export const createInitialize = createSmartAction<
  RequestAction<any, any>,
  [{
    names: string;
    symbols: string;
    contractAddress: string;
    imageUrl: string;
    description: string;
    iconUrl: string;
    metadataApiUrl: string;
    back_image: string
    url: string
  }]
>('createInitialize', ({
  names,
  symbols,
  imageUrl,
  description,
  contractAddress,
  iconUrl,
  metadataApiUrl,
  back_image,
  url
}) => {
  return {
    request: {
      promise: (async function () { })(),
    },
    meta: {
      onRequest: (
        request: { promise: Promise<any> },
        action: RequestAction,
        store: Store<RootState> & { dispatchRequest: DispatchRequest },
      ) => {
        return {
          promise: (async function () {
            const {
              data: { web3, address }, } = getQuery(store.getState(), {
                type: setAccount.toString(),
                action: setAccount,
              });

            const NewContracts = new web3.eth.Contract(
              MyNftAbi,
              contractAddress
            );

            return NewContracts.methods.initialize
              (names, symbols, metadataApiUrl).send({
                from: address,
              }).on('error', (error: any) => {
                console.log(error);
              }).then((res: any) => {
                return store.dispatchRequest(fetchAddCollections({
                  contractAddress: contractAddress,
                  name: names,
                  imageUrl: imageUrl,
                  description: description,
                  iconUrl: iconUrl,
                  ownerAddress: address,
                  metadataApiUrl: metadataApiUrl,
                  back_image: back_image,
                  url: url,
                }))
              });

          })(),
        };
      },
      asMutation: false,
    },
  };
});

export const fetchAddCollections = createSmartAction<
  RequestAction<any, any>,
  [
    {
      contractAddress: string;
      name: string;
      imageUrl: string;
      description: string;
      iconUrl: string;
      ownerAddress: string;
      metadataApiUrl: string;
      back_image: string;
      url: string;
    }
  ]
>('fetchAddCollections', ({
  contractAddress,
  name,
  imageUrl,
  description,
  iconUrl,
  ownerAddress,
  metadataApiUrl,
  back_image,
  url
}) => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/nft-market-console/v1/collections`,
    method: 'post',
    params: {},
    data: {
      contractAddress: contractAddress,
      name: name,
      imageUrl: imageUrl,
      description: description,
      iconUrl: iconUrl,
      ownerAddress: ownerAddress,
      back_image: back_image,
      links: url
    },
  },
  meta: {
    asMutation: false,
    auth: true,
    orgID: true,
    driver: 'axios',
    getData: data => {
      // if (data.code === 401) {
      //   store.dispatchRequest(disconnect());
      // }
      return data;
    },
  },
}));


export const fetchMint = createSmartAction(
  'fetchMint', ({
    tokenId,
    contractAddress,
    imageUrl,
    name,
    description,
    attributes,
    externalLink,
    properties,
    levels,
    stats,
    supply,
    openLock,
    openExplicit
  }: {
    tokenId: string;
    contractAddress: string;
    imageUrl: string;
    name: string;
    description: string;
    attributes: IAttributes[];
    externalLink: string;
    properties: IAttributes[];
    levels: IAttributes[];
    stats: IAttributes[];
    supply: string;
    openLock: boolean
    openExplicit: boolean
  }) => {
  return {
    request: {
      promise: (async function () { })(),
    },
    meta: {
      onRequest: (
        request: { promise: Promise<any> },
        action: RequestAction,
        store: Store<RootState> & { dispatchRequest: DispatchRequest },
      ) => {
        return {
          promise: (async function () {
            const {
              data: { web3, address }, } = getQuery(store.getState(), {
                type: setAccount.toString(),
                action: setAccount,
              });

            const Contracts = new web3.eth.Contract(
              MyNftAbi,
              contractAddress
            );
            return await Contracts.methods.safeMint(address, tokenId).send({ from: address })
              .on('error', (error: any) => {
                console.log('error', error)
                return error;
              })
              .then((res: any) => {
                console.log('safeMint------------>', res)
                return store.dispatchRequest(
                  fetchMetadate({
                    tokenID: tokenId,
                    contractAddress: contractAddress,
                    imageUrl: imageUrl,
                    name: name,
                    description: description,
                    attributes: attributes,
                    externalLink,
                    properties,
                    levels,
                    stats,
                    supply,
                    openLock,
                    openExplicit
                  }))
              });
          })(),
        };
      },
      asMutation: false,
    },
  };
});

export const fetchMetadate = createSmartAction<
  RequestAction<any, any>,
  [
    {
      tokenID: string;
      contractAddress: string;
      imageUrl: string;
      name: string;
      description: string;
      attributes: IAttributes[];
      externalLink: string;
      properties: IAttributes[];
      levels: IAttributes[];
      stats: IAttributes[];
      supply: string;
      openLock: boolean
      openExplicit: boolean
    }
  ]
>('fetchMetadate', ({ tokenID,
  contractAddress,
  imageUrl,
  name,
  description,
  attributes,
  externalLink,
  properties,
  levels,
  stats,
  supply,
  openLock,
  openExplicit
}) => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/nft-market/v1/nft/metadata`,
    method: 'post',
    params: {},
    data: {
      'tokenID': parseInt(tokenID),
      'contractAddress': contractAddress,
      'metadataJson': {
        'image': imageUrl,
        'name': name,
        'attributes': attributes,
        'description': description,
        'externalLink': externalLink,
        'properties': properties,
        'levels': levels,
        'stats': stats,
        'supply': supply,
        'openLock': openLock,
        'openExplicit': openExplicit
      },
    },
  },
  meta: {
    asMutation: false,
    auth: false,
    orgID: false,
    driver: 'axios',
    getData: data => {
      return data.data;
    },
  },
}));


// export const uploadAsyncImg = createSmartAction<
//   RequestAction<any, any>,
//   [
//     {
//       file: any;
//     }
//   ]
// >('uploadImg', ({ file }) => ({
//   request: {
//     url: `${process.env.REACT_APP_API_BASE}/nft-cloud-storage/v1/images`,
//     method: 'post',
//     params: {},
//     data: file,
//   },
//   meta: {
//     asMutation: false,
//     auth: true,
//     orgID: true,
//     driver: 'axios',
//     getData: data => {
//       console.log("----data----------------", data)
//       if (data.code === 401) {
//         console.log("---------------------")
//       }
//       return data.data;
//     },
//   },
// }));

export const uploadAsyncImg = async (file: any) => {
  const token = getJWTToken() ?? '';
  const orgID = getOrgID() ?? '';
  let res;
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE}/nft-cloud-storage/v1/images`,
    data: file,
    headers: {
      token: token ?? '',
      orgID: orgID ?? '',
    }
  }).then((response) => {
    res = response.data;
  }, error => {
    res = error.response.data;
  })
  return res
}

// export const uploadAsyncImg = (file: any) => {
//   const token = getJWTToken() ?? '';
//   const orgID = getOrgID() ?? '';

//   axios({
//     method: 'post',
//     url: `${process.env.REACT_APP_API_BASE}/nft-cloud-storage/v1/images`,
//     data: file,
//     headers: {
//       token: token ?? '',
//       orgID: orgID ?? '',
//     }
//   }).then(res => {
//     console.log(res);
//   }, error => {
//     console.log(error.response);
//   })
//   // const data = res.data;
//   // console.log(res);
//   // return res;
// }

