import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath } from 'react-router-dom';
import { Route } from 'react-router-dom';

export const PATH_ACTIVITIES = '/activities';

export const ActivitiesRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_ACTIVITIES,
    generatePath: () => generatePath(PATH_ACTIVITIES),
  },
};

const LoadableNftListContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.Activities),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function ActivitiesRoutes() {
  return (
    <Route
      path={ActivitiesRoutesConfig.Index.path}
      exact={true}
      component={LoadableNftListContainer}
    />
  );
}
