import classNames from 'classnames';
import bnbLogo from 'modules/account/assets/bnb.svg';
import { useAccount } from 'modules/account/hooks/useAccount';
import { truncateWalletAddr } from 'modules/common/utils/truncateWalletAddr';
import { Button } from 'modules/uiKit/Button';
import React, { useRef } from 'react';
import { FocusOn } from 'react-focus-on';
import { DefaultRandomAvatar } from '../../../common/components/DefaultRandomAvatar';
import { WalletCard } from '../WalletCard';
import { useWalletDropdown } from './useWalletDropdown';
import { useWalletStyles } from './useWalletStyles';
import { getNativeTokenSymbol } from '../../../common/conts';
import { useWeb3Balance } from 'modules/account/hooks/useWeb3React';
import { useQuery } from '@redux-requests/react';
import { fetchProfileInfo, IProfileInfo } from 'modules/user/actions/user';
interface IWalletProps {
  address?: string;
  img?: string;
  name?: string;
}

// TODO: replace with real data
const logo = bnbLogo;

export const WalletComponent = ({
  address = '',
  img,
  name = 'Unnamed',
}: IWalletProps) => {
  const classes = useWalletStyles();

  const { chainId } = useAccount();
  const currency = getNativeTokenSymbol(chainId);


  const {
    isOpened,
    handleDisconnect,
    handleClose,
    handleOpen,
  } = useWalletDropdown();

  const controlRef = useRef<HTMLButtonElement>(null);

  const { balance } = useWeb3Balance();

  return (
    <>
      <Button
        className={classes.accountBtn}
        onClick={handleOpen}
        ref={controlRef}
      >
        {truncateWalletAddr(address)}
        <DefaultRandomAvatar src={img} className={classes.walletLogo} />
      </Button>

      <FocusOn
        enabled={isOpened}
        onEscapeKey={handleClose}
        onClickOutside={handleClose}
        focusLock={true}
        scrollLock={false}
        shards={[controlRef]}
      >
        <div
          className={classNames(
            classes.dropdown,
            isOpened && classes.dropdownActive,
          )}
        >
          <WalletCard
            address={address}
            name={name}
            currency={currency}
            logo={logo}
            balance={balance}
            handleDisconnect={handleDisconnect}
            handleClose={handleClose}
          />
        </div>
      </FocusOn>
    </>
  );
};

export const Wallet = () => {
  const { address } = useAccount();

  // const { data } = useQuery<IProfileInfo | null>({
  //   type: fetchProfileInfo.toString(),
  // });
  const { data: profileInfo } = useQuery<IProfileInfo | null>({
    type: fetchProfileInfo.toString(),
  });

  return (
    <WalletComponent
      address={address}
      name={""}
      img={profileInfo?.avatar || "https://d3sl9juju0xzg8.cloudfront.net/images/IconHeroScott_4x.png"}
    />
  );
};
