import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useParams } from 'react-router';

const PATH_MY_ASSETS_DETAIL = '/my-assets/detail/:contractAddress/:tokenId/:onSale/:price/:index/:symbol';

export const MyAssetsDetailRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_MY_ASSETS_DETAIL,
    generatePath: (contractAddress: string, tokenId: string, onSale: boolean, price: string, index: string, symbol: string) => generatePath(PATH_MY_ASSETS_DETAIL, { contractAddress, tokenId, onSale, price, index, symbol }),
    useParams: () => {
      const { contractAddress, tokenId, onSale, price, index, symbol } = useParams<{
        contractAddress: string;
        tokenId: string;
        onSale: string;
        price: string;
        index: string;
        symbol: string;
      }>();
      return {
        contractAddress,
        tokenId,
        onSale,
        price,
        index,
        symbol
      };
    },
  },
};

const LoadableNftListContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.MyAssetsDetail),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function MyAssetsDetailRoutes() {
  return (
    <Route
      path={MyAssetsDetailRoutesConfig.Index.path}
      exact={true}
      component={LoadableNftListContainer}
    />
  );
}
