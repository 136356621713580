import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath } from 'react-router-dom';
import { Route } from 'react-router-dom';

const PATH_STATS = '/explore';

export const ExploreRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_STATS,
    generatePath: () => generatePath(PATH_STATS),
  },
};

const LoadableNftListContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.Explore),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function ExploreRoutes() {
  return (
    <Route
      path={ExploreRoutesConfig.Index.path}
      exact={true}
      component={LoadableNftListContainer}
    />
  );
}
