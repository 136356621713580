import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useParams } from 'react-router';

export const PATH_COLLECTIONS = '/collections';
export const PATH_ADD_COLLECTIONS = '/collections_add/:tabType';

export const CollectionsConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_COLLECTIONS,
    generatePath: (contractAddress: string) => generatePath(PATH_COLLECTIONS, { contractAddress }),
    useParams: () => {
      const { contractAddress } = useParams<{
        contractAddress: string;
      }>();
      return { contractAddress };
    },
  },
  AddCollections: {
    path: PATH_ADD_COLLECTIONS,
    generatePath: (tabType: string) => generatePath(PATH_ADD_COLLECTIONS, { tabType }),
    useParams: () => {
      const { tabType } = useParams<{
        tabType: string;
      }>();
      return { tabType };
    },
  },
};

const LoadableCollectionsContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.Collections),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

const LoadableCollectionsAddContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/edit').then(module => module.CreateContract),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function CollectionsRoutes() {
  return (
    <Route
      path={CollectionsConfig.Index.path}
      exact={true}
      component={LoadableCollectionsContainer}
    />
  );
}

export function CollectionsAddRoutes() {
  return (
    <Route
      path={CollectionsConfig.AddCollections.path}
      exact={true}
      component={LoadableCollectionsAddContainer}
    />
  );
}