import { makeStyles, Theme } from '@material-ui/core/styles';
import { mainTheme } from 'modules/themes/mainTheme';

export const useDefaultLayoutStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 375,
    background: mainTheme.palette.background.default,
  },

  darkBg: {
    background: mainTheme.palette.background.default,
  },

  main: {
    flexGrow: 1,
  },
}));
