import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_USER = '/user';

export const UserRoutesConfig: { [key: string]: RouteConfiguration } = {

  UserService: {
    path: PATH_USER,
    generatePath: () => PATH_USER,
  },
};

const LoadableTermsOfServiceContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/user').then(module => module.User),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function UserRoutes() {
  return (
    <>
      <Route
        path={UserRoutesConfig.UserService.path}
        exact={true}
        component={LoadableTermsOfServiceContainer}
      />
    </>
  );
}
