import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useParams } from 'react-router';

const PATH_INFEX = '/nft-list/:contractAddress/:name';

export const NftListRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_INFEX,
    generatePath: (contractAddress: string, name: string) => generatePath(PATH_INFEX, { contractAddress, name }),
    useParams: () => {
      const { contractAddress, name } = useParams<{
        contractAddress: string;
        name: string;
      }>();
      return { contractAddress, name };
    },
  },
};

const LoadableNftListContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.NftList),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function NftListRoutes() {
  return (
    <Route
      path={NftListRoutesConfig.Index.path}
      exact={true}
      component={LoadableNftListContainer}
    />
  );
}
