import { Route, Switch, Redirect } from 'react-router-dom';
import { DefaultLayout } from './modules/layout/components/DefautLayout';
import { PageNotFound } from './modules/router/components/PageNotFound';
import { Themes } from './modules/themes/types';
import { SwitchNetwork } from 'modules/switchnetwork';
import { PrivateRoute } from 'modules/router/components/PrivateRoute';
import {
  StatementRoutes as StatementRoute,
  StatementRoutesConfig as StatementRoutesConfigs,
} from './modules/statement/Routes';
import { IndexRoutes, IndexRoutesConfig } from './modules/home/Routes';
import { NftListRoutes, NftListRoutesConfig } from './modules/nftList/Routes';
import {
  MyAssetsRoutes,
  MyAssetsRoutesConfig,
} from './modules/myAssets/Routes';
import { StatsRoutes, StatsRoutesConfig } from 'modules/stats/Routes';
import { ExploreRoutes, ExploreRoutesConfig } from 'modules/explore/Routes';
import { CreateRoutes, CreateRoutesConfig } from 'modules/createNft/Routes';
import {
  CollectionsRoutes,
  CollectionsConfig,
  CollectionsAddRoutes,
} from './modules/collections/Routes';
import { ActivitiesRoutes, ActivitiesRoutesConfig } from 'modules/activities/Routes';

import {
  NftDetailRoutes,
  NftDetailRoutesConfig,
} from './modules/nftDetail/Routes';

import {
  MyAssetsDetailRoutes,
  MyAssetsDetailRoutesConfig,
} from './modules/myAssetsDetail/Routes';

import {
  UserRoutes,
  UserRoutesConfig,
} from './modules/user/Routes';

import { GuideRoutes, GuideRoutesConfig } from './modules/guide/Routes'

import { DeployRoutes, DeployRoutesConfig } from './modules/deployer/Routes';

export function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={IndexRoutesConfig.Index.path} />}
      />

      <Route
        exact
        path={StatementRoutesConfigs.OurStory.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <StatementRoute />
          </DefaultLayout>
        )}
      />

      <Route
        exact
        path={StatementRoutesConfigs.TermsOfService.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <StatementRoute />
          </DefaultLayout>
        )}
      />
      <Route
        exact
        path={IndexRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <IndexRoutes />
          </DefaultLayout>
        )}
      />
      <Route
        exact
        path={NftListRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <NftListRoutes />
          </DefaultLayout>
        )}
      />
      <Route
        exact
        path={MyAssetsRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <MyAssetsRoutes />
          </DefaultLayout>
        )}
      />
      <Route
        exact
        path={StatsRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <StatsRoutes />
          </DefaultLayout>
        )}
      />

      <Route
        exact
        path={ActivitiesRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <ActivitiesRoutes />
          </DefaultLayout>
        )}
      />

      <Route
        exact
        path={ExploreRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <ExploreRoutes />
          </DefaultLayout>
        )}
      />
      <Route
        exact
        path={CreateRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <CreateRoutes />
          </DefaultLayout>
        )}
      />
      <Route
        exact
        path={CollectionsConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <CollectionsRoutes />
          </DefaultLayout>
        )}
      />

      <Route
        exact
        path={CollectionsConfig.AddCollections.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <CollectionsAddRoutes />
          </DefaultLayout>
        )}
      />

      <Route
        exact
        path={NftDetailRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <NftDetailRoutes />
          </DefaultLayout>
        )}
      />
      <Route
        exact
        path={UserRoutesConfig.UserService.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <UserRoutes />
          </DefaultLayout>
        )}
      />
      <Route
        exact
        path={GuideRoutesConfig.GuideService.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <GuideRoutes />
          </DefaultLayout>
        )}
      />

      <Route
        exact
        path={GuideRoutesConfig.FaqService.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <GuideRoutes />
          </DefaultLayout>
        )}
      />

      <Route
        exact
        path={GuideRoutesConfig.HelpCenterService.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <GuideRoutes />
          </DefaultLayout>
        )}
      />

      <Route
        exact
        path={MyAssetsDetailRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <MyAssetsDetailRoutes />
          </DefaultLayout>
        )}
      />
      <PrivateRoute
        exact
        path={['/switchnetwork']}
        render={() => <SwitchNetwork />}
      />

      <Route
        exact
        path={DeployRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <DeployRoutes />
          </DefaultLayout>
        )}
      />

      <Route
        render={() => (
          <DefaultLayout>
            <PageNotFound />
          </DefaultLayout>
        )}
      />
    </Switch>
  );
}
