import { Container } from '@material-ui/core';
import React from 'react';
import { useFooterStyles } from './FooterStyles';
import Icon from './assets/icon.svg';
import Twitter from 'modules/nftList/assets/twitter.svg';
import Instagram from 'modules/nftList/assets/instagram.svg';
import Discord from 'modules/nftList/assets/discord.svg';
import { useHistory } from 'react-router-dom';
import { GuideRoutesConfig } from 'modules/guide/Routes';
import { ActivitiesRoutesConfig } from 'modules/activities/Routes';
import { StatsRoutesConfig } from 'modules/stats/Routes';

export const Footer = () => {
  const classes = useFooterStyles();
  const history = useHistory();
  return (
    <footer className={classes.root}>
      <Container maxWidth={false} className={classes.container}>
        <div className={classes.copyright}>
          <img src={Icon} alt="" />
        </div>
        <div className={classes.list}>
          <ul>
            <li>Marketplace</li>
            <li>Discover</li>
            <li>Coming Soon</li>
            <li onClick={() => { history.push(StatsRoutesConfig.Index.path) }}>Rankings</li>
            <li onClick={() => { history.push(ActivitiesRoutesConfig.Index.path) }}>Activities</li>
          </ul>
          <ul>
            <li>Resources</li>
            <li onClick={() => { history.push(GuideRoutesConfig.HelpCenterService.path) }}>Help Center</li>
            <li onClick={() => { history.push(GuideRoutesConfig.GuideService.path) }}>Trader’s Guide</li>
            <li>Features</li>
            <li>Partners</li>
            <li>Blog</li>
            <li>Platform Status</li>
            <li onClick={() => { history.push(GuideRoutesConfig.FaqService.path) }}>FAQ</li>
          </ul>
          <ul>
            <li>Links</li>
            <li>Privacy Policy</li>
            <li>Terms of Service</li>
          </ul>
        </div>
        <div>
          <p>Join the community</p>
          <div className={classes.join}>
            <div className={classes.iconSvg}><img src={Twitter} alt="" /></div>
            <div className={classes.iconSvg}><img src={Instagram} alt="" /></div>
            <div className={classes.iconSvg}><img src={Discord} alt="" /></div>
          </div>
        </div>
      </Container>
    </footer>
  );
};
