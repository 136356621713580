import React, { useCallback, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useAccount } from 'modules/account/hooks/useAccount';
import { t } from 'modules/i18n/utils/intl';
import { Button } from 'modules/uiKit/Button';
import { Wallet } from '../Wallet';
import { useHeaderStyles } from './HeaderStyles';
import { Logo } from 'modules/layout/components/Logo';
import classNames from 'classnames';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useHeaderLinksStyles } from '../HeaderLinks/HeaderLinksStyles';
// import { PATH_MY_ASSETS } from 'modules/myAssets/Routes';
import { PATH_STATS } from 'modules/stats/Routes';
import { CreateRoutesConfig } from 'modules/createNft/Routes';
import Search from './assets/search.svg';
import { isChainSupported } from 'modules/common/conts';
import {
  SelectChainDialog,
  useDialogState,
} from 'modules/layout/components/Header/components/SelectChainDialog';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import { fetchCollections, ICollectionDetail } from 'modules/collections/actions/collectionActions';
import { useHistory } from 'react-router-dom';

const lindSpaceStyles = { marginLeft: 36 };

export const Header = () => {
  const { isConnected, handleConnect, loading,
    chainId, address
  } = useAccount();
  const classes = useHeaderStyles();
  const linksClasses = useHeaderLinksStyles();
  const history = useHistory();
  const dispatchRequest = useDispatchRequest();
  const renderedWallet = <Wallet />;
  const {
    opened: openedSelectChainDialog,
    open: openSelectChainDialog,
    close: closeSelectChainDialog,
  } = useDialogState();
  const { data: collectionsList } = useQuery<ICollectionDetail>({
    type: fetchCollections.toString(),
  });

  useEffect(() => {
    if (address) {
      dispatchRequest(
        fetchCollections({ index: 0, size: 100, address: address }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, dispatchRequest]);

  const renderedDesktop = (
    <>
      {!isConnected && (
        <Button onClick={handleConnect} className={classNames(classes.connect, classes.connectBorder)} loading={loading}>
          {t('header.connect')}
        </Button>
      )}
      {isConnected && (
        <div>
          {/* <Button
            component={NavLink}
            variant="text"
            style={lindSpaceStyles}
            activeClassName={linksClasses.activeLink}
            className={classNames(linksClasses.link, classes.links)}
            to={PATH_MY_ASSETS}
          >
            My Assets
          </Button> */}

          {renderedWallet}
        </div>
      )}
    </>
  );
  const handleCreate = useCallback(e => {
    e.stopPropagation();
    e.preventDefault();
    if (!isConnected) {
      handleConnect();
    } else if (!isChainSupported.includes(chainId)) {
      openSelectChainDialog();
    } else if (collectionsList.total > 0) {
      history.push('/create')
    } else {
      history.push('/collections_add/create')
    }
  }, [chainId, handleConnect, isConnected, collectionsList, history, openSelectChainDialog]);

  return (
    <header className={classNames(classes.root)}>
      <Container className={classes.container} maxWidth={false}>
        <SelectChainDialog
          isOpen={openedSelectChainDialog}
          onClose={closeSelectChainDialog}
          currentChain={chainId}
        />
        <Box className={classes.navs}>
          <Logo />
        </Box>
        <Box display={'flex'} alignItems={'center'} ml={'-100px'}>
          <label className={classes.newsearchBox}><img className={classes.searchIcon} src={Search} alt="" /><input type={'text'} className={classes.hearderSearch} placeholder='Search' /></label>
          <Button
            component={NavLink}
            variant="text"
            style={lindSpaceStyles}
            activeClassName={linksClasses.activeLink}
            className={linksClasses.link}
            to={'/index'}
          >
            Marketplace
          </Button>
          <Button
            component={NavLink}
            variant="text"
            style={lindSpaceStyles}
            activeClassName={linksClasses.activeLink}
            className={linksClasses.link}
            to={PATH_STATS}
          >
            Stats
          </Button>
          {/* <Button
            component={NavLink}
            variant="text"
            style={lindSpaceStyles}
            activeClassName={linksClasses.activeLink}
            className={linksClasses.link}
            to={'https://opensea.io/learn'}
          >
            Resources
          </Button> */}
          <Button
            component={NavLink}
            variant="text"
            style={lindSpaceStyles}
            activeClassName={linksClasses.activeLink}
            className={linksClasses.link}
            onClick={handleCreate}
            to={CreateRoutesConfig.Index.path}
          >
            Create NFT
          </Button>
        </Box>
        {renderedDesktop}
      </Container>
    </header>
  );
};
