import { fade, makeStyles, Theme } from '@material-ui/core';

export const FOOTER_MIN_HEIGHT = 64;

export const useFooterStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: '#071023',
    color: theme.palette.common.white,
    borderTop: `1px solid ${fade(theme.palette.text.primary, 0.1)}`,
    paddingTop: '48px',
    paddingBottom: '48px',
    [theme.breakpoints.up('md')]: {
      minHeight: FOOTER_MIN_HEIGHT,
    },
  },

  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    [theme.breakpoints.up('WXGAPlus')]: {
      paddingLeft: 25,
      paddingRight: 25,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 0)
    },
  },
  copyright: {
    fontSize: 18,
  },
  list: {
    display: 'flex',
    width: '600px',
    justifyContent: 'space-between',
    '& ul': {
      padding: '0'
    },
    '& li': {
      listStyle: 'none',
      marginTop: '16px',
      color: '#8787A2',
      fontSize: '14px',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:first-child': {
        marginTop: '0',
        fontSize: '16px',
        lineHeight: '24px',
        cursor: 'auto',
        color: '#fff'
      },
    }
  },
  join: {
    display: 'flex'
  },
  iconSvg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    width: '48px',
    height: '48px',
    marginRight: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    cursor: 'pointer',
    '& img': {
      display: 'block'
    }
  }
}));
