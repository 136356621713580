import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath } from 'react-router-dom';
import { Route } from 'react-router-dom';

const PATH_STATS = '/create';

export const CreateRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_STATS,
    generatePath: () => generatePath(PATH_STATS, {}),
    useParams: () => { },
  },
};

const LoadableNftListContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.Create),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function CreateRoutes() {
  return (
    <Route
      path={CreateRoutesConfig.Index.path}
      exact={true}
      component={LoadableNftListContainer}
    />
  );
}
