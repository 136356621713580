import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useParams } from 'react-router';

export const PATH_MY_ASSETS = '/my-assets';

export const MyAssetsRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_MY_ASSETS,
    generatePath: (contractAddress: string) => generatePath(PATH_MY_ASSETS, { contractAddress }),
    useParams: () => {
      const { contractAddress } = useParams<{
        contractAddress: string;
      }>();
      return { contractAddress };
    },
  },
};

const LoadableNftListContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens').then(module => module.MyAssets),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function MyAssetsRoutes() {
  return (
    <Route
      path={MyAssetsRoutesConfig.Index.path}
      exact={true}
      component={LoadableNftListContainer}
    />
  );
}
