import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { generatePath } from 'react-router-dom';
import { Route } from 'react-router-dom';

const PATH_INFEX = '/contract-deployer';

export const DeployRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_INFEX,
    generatePath: () => generatePath(PATH_INFEX),
  },
};

const LoadDeployContainer: LoadableComponent<any> = loadable(
  async () => import('./page/deploy').then(module => module.default),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function DeployRoutes() {
  return (
    <Route
      path={DeployRoutesConfig.Index.path}
      exact={true}
      component={LoadDeployContainer}
    />
  );
}
