import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import logo01 from './assets/logo02.png';
// import logo from './assets/logo.png';
import logo from '../Footer/assets/icon.svg';
import { useLogoStyles } from './LogoStyles';

export const Logo = () => {
  const classes = useLogoStyles();
  return (
    <Link
      to={'/index'}
      className={classes.root}
    >
      <img
        alt="Meta Apes"
        className={classNames(classes.img, classes.ableDark)}
        src={logo}
      />
      <img
        alt="Meta Apes"
        className={classNames(classes.img, classes.ableLight)}
        src={logo}
      />
    </Link>
  );
};
