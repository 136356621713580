export enum TokenSymbol {
  ETH = 'wETH',
  BNB = 'wBNB',
  ANKR = 'COQ'
}

export enum ChainSymbol {
  ETH = 'wETH',
  BNB = 'wBSC',
  ANKR = 'COQ',
}
