import { makeStyles, Theme } from '@material-ui/core';

export const useConnectWalletStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    minHeight: 300,
    height: '70vh',
    maxHeight: 600,
    padding: theme.spacing(5, 0),
    textAlign: 'center',
  },
  caption: {
    fontSize: 40,
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 16,
    margin: theme.spacing(2, 'auto', 3),
    maxWidth: 320,
  },
  connectBtn: {
    fontSize: 16,
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    height: '48px',
    width: '200px',
    lineHeight: '48px',
    color: '#fff',
  },
}));
