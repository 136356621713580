import { createAction as createSmartAction } from 'redux-smart-actions';
import { RequestAction } from '@redux-requests/core';
import axios from 'axios';

export interface INFTList {
  Total: number;
  Collection: INFTItems[]
}
export interface INFTItems {
  id: number;
  contract_address: string;
  description: string;
  icon_url: string;
  image_url: string;
  name: string;
  owner_address: string;
  on_sale_amount: string;
}


export interface INFTDetail {
  Total: number;
  Nft: INFTListItems[]
}
export interface INFTListItems {
  Currency: INFTCurrency[];
  id: number;
  contract_address: string;
  index: number;
  on_sale: boolean;
  token_id: string;
}

export interface INFTCurrency {
  price: number;
  contract_address: string;
  symbol: string;
}

export interface IInfoData {
  collection_name: string;
  collection_image: string;
  collection_address: string;
  owner_address: string;
  for_sale: boolean;
  on_sale: boolean;
  favorite: number;
  watched: number;
  currency: ICurrency[];
  index: number
}

export interface ICurrency {
  contract_address: string;
  symbol: string;
  price: number;
}

export interface IActivitiesData {
  contract_address: string;
  from: string;
  id: number;
  quantity: number;
  timestamp: number;
  to: string;
  token_id: number;
  currency: ICurrency[]
}

export interface IProfileInfo {
  user_name: string;
  avatar: string;
  crypto_address: string;
}



export const fetchUserCollection = createSmartAction<
  RequestAction<any, INFTList>,
  [address?: string]
>('fetchUserCollection', (address) => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/nft-market/v1/collection/info`,
    method: 'get',
    params: {
      addresses: address
    },
  },
  meta: {
    asMutation: false,
    // auth: true,
    driver: 'axios',
    getData: data => {
      if (data.Collection.length < 1) {
        return {};
      }
      return data;
    },
  },
}));
const formatUserUrl = (address: string, favorite?: boolean, created?: boolean, collections?: string[]) => {
  console.log(collections);
  let url = `${process.env.REACT_APP_API_BASE}/nft-market/v1/nft/${address}`;
  if (favorite || created || (collections && collections.length > 0)) {
    url = url + '?'
  }
  if (collections && collections.length > 0) {
    collections.map((items: string, index) => {
      url = index === 0 ? url + 'collections=' + items : url + '&collections=' + items;
      return '';
    })
  }
  if (favorite) {
    url = (collections && collections.length > 0) ? url + '&favorite=true' : url + 'favorite=true';
  }
  if (created) {
    url = ((collections && collections.length > 0) || favorite) ? url + '&created=true' : url + 'created=true';
  }
  return url;
}

export const fetchUserNft = createSmartAction<
  RequestAction<any, INFTDetail>,
  [{ address: string, favorite?: boolean, created?: boolean, collections?: string[] }]
>('fetchUserNft', ({ address, favorite, created, collections }) => ({
  request: {
    url: formatUserUrl(address, favorite, created, collections),
    method: 'get',
    params: {},
  },
  meta: {
    asMutation: false,
    // auth: true,
    driver: 'axios',
    getData: data => {
      return data;
    },
  },
}));



export const fetchNftInfo = async (contractAddress?: string, tokenId?: string) => {
  const res = await axios.get(`${process.env.REACT_APP_API_BASE}/nft-market/v1/nft/sale_info?contract_address=${contractAddress?.toLowerCase()}&token_id=${tokenId}`);
  const data = res.data;
  return data;
}

const formatActivityUrl = (address: string, collections?: string[]) => {
  let url = `${process.env.REACT_APP_API_BASE}/nft-market/v1/nft/activities/${address}`;
  if (collections && collections.length > 0) {
    url = url + '?';
    collections.map((items: string, index) => {
      url = index === 0 ? url + 'collections=' + items : url + '&collections=' + items;
      return '';
    })
  }
  return url;
}

export const fetchUserActivities = createSmartAction<
  RequestAction<any, IActivitiesData>,
  [{ address: string, collections?: string[] }]
>('fetchUserActivities', ({ address, collections }) => ({
  request: {
    url: formatActivityUrl(address, collections),
    method: 'get',
    params: {},
  },
  meta: {
    asMutation: false,
    // auth: true,
    driver: 'axios',
    getData: data => {
      console.log(data);
      return data.Nft || [];
    },
  },
}));


export const fetchProfileInfo = createSmartAction<
  RequestAction<any, IProfileInfo>,
  []
>('fetchProfileInfo', () => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/nft-market-console/v1/user/profile`,
    method: 'get',
    params: {},
  },
  meta: {
    asMutation: false,
    driver: 'axios',
    auth: true,
    orgID: true,
    getData: data => {
      console.log(data);
      return data.data;
    },
  },
}));

export const fetchUpdateProfileInfo = createSmartAction<
  RequestAction<any, any>,
  [{ avatar: string, user_name: string }]
>('fetchUpdateProfileInfo', ({ avatar, user_name }) => ({
  request: {
    url: `${process.env.REACT_APP_API_BASE}/nft-market-console/v1/user/profile`,
    method: 'put',
    data: {
      user_name: user_name,
      avatar: avatar
    },
  },
  meta: {
    asMutation: false,
    driver: 'axios',
    auth: true,
    orgID: true,
    getData: data => {
      console.log(data);
      return data;
    },
  },
}));



